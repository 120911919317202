<template>
	<date-picker
		:value="value"
		@change='$emit("change", $event)'
		:placeholder='placeholder ? placeholder : $t("account.form.placeholder.choose_a_date")'
		:range='range'
		format="DD/MM/YYYY"
		:lang="$route.params.lang ? $route.params.lang : 'uk'"
		:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
		range-separator=' — '
		:append-to-body='false'
		class='a-booking-form-datepicker a-booking-form-datepicker-complex-route a-booking-form-datepicker-charter'
		:class='{"mx-datepicker-right": right, "invalid": errors}'
		:editable='false'
		value-type='timestamp'
		:partial-update='true'
		:open.sync="open"
	>
		<template v-slot:header>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-auto">
					<div class='mx-datepicker-header-close' @click="open = false">
						<v-icon icon="cross" size="20"></v-icon>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:content>
			<div class='mx-range-wrapper'>
				<div v-for='(item, i) in calendars' :key='i'>
					<calendar-panel
						:value="innerValue"
						:get-classes="getClasses"
						:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
						:calendar="item"
						@update:calendar="handleUpdateCalendar"
						@select="handleSelect"
					/>
				</div>
			</div>
		</template>
	</date-picker>
</template>

<script>
	import { addMonths } from "date-fns";
	import DatePicker from "vue2-datepicker";

	const { CalendarPanel } = DatePicker;

	function isValidDate(date) {
		return date instanceof Date && !isNaN(date);
	}

	export default {
		data: () => ({
			open: false,
			innerValue: [],
			hoveredValue: null,
			calendars: [],
			size: 2,
			setTimeout: null,
			isArrival: false
		}),
		props: {
			value: {
				type: [String, Number, Array]
			},
			range: {
				type: Boolean,
				default: false
			},
			right: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String
			},
			errors: {
				type: [Boolean, Array],
				default: false
			},
			schedulers: Object
		},
		components: {
			CalendarPanel
		},
		created() {
			this.innerValue = this.range ? (this.value ? this.value.map(el => new Date(el)) : []) : (this.value ? new Date(this.value) : null);
		},
		methods: {
			handleUpdateCalendar(date) {
				this.calendars = [];
				for (let i = 0; i < this.size; i++) {
					this.calendars.push(addMonths(date, i))
				}

				this.size = window.innerWidth <= 600 ? this.size + 1 : 2;
			},
			getClasses(cellDate, currentDates, classes) {
				const result = [];
				if (/not-current-month/.test(classes)) {
					return result;
				}

				let current = this.range ? (this.isArrival ? this.schedulers.arrival : this.schedulers.departure) : this.schedulers.departure;

				if(!(current && current.some(v => this.$moment(v.departureDate).format('YYYY-MM-DD') === this.$moment(cellDate).format('YYYY-MM-DD')))) {
					result.push("disabled");
				}
				return result;
			},
			handleSelect(date) {
				if(this.range) {
					const [startValue, endValue] = this.innerValue;
					if (isValidDate(startValue) && !isValidDate(endValue)) {
						if (startValue.getTime() > date.getTime()) {
							this.innerValue = [date, startValue];
						} else {
							this.innerValue = [startValue, date];
						}
						this.$emit("change", this.innerValue.map(el => el.getTime()));
						this.open = false;
						this.isArrival = false;
					} else {
						this.innerValue = [date, new Date(NaN)];
						this.isArrival = true;
					}
				} else {
					this.innerValue = date;
					this.$emit("change", this.innerValue.getTime());
					this.open = false;
				}
			}
		},
		watch: {
			value() {
				this.innerValue = this.range ? (this.value ? this.value.map(el => new Date(el)) : []) : (this.value ? new Date(this.value) : null);
			},
			open() {
				if(this.open) {
					this.size = window.innerWidth <= 600 ? 12 : 2;

					this.setTimeout = setTimeout(() => {
						let list = document.querySelector('.a-booking-form-datepicker-complex-route .mx-datepicker-main');
						let self = this;

						if(list) list.addEventListener('scroll', function() {
							if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
								self.handleUpdateCalendar(new Date());
							}
						});

						let currentDate = this.range ? (this.value ? new Date(this.value[0]) : new Date()) : (this.value ? new Date(this.value) : new Date());
						this.handleUpdateCalendar(currentDate);
					}, 150)
				} else {
					clearTimeout(this.setTimeout);
				}
			}
		}
	}
</script>

<style lang="scss">
	.a-booking-form-datepicker-charter {
		.not-current-month {
			pointer-events: none;
		}
	}
</style>
